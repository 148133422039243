import {
    BonusPagesEnum,
    PolicyPagesEnum,
    IBonusPagesConfig,
    IPolicyPagesConfig,
} from "@finbackoffice/fe-core";
import {
    useBonusPagesConfig,
    usePoliciesPagesConfig,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { createContext, FC, useCallback, useMemo } from "react";
import { SkinVersions } from "@finbackoffice/site-server-core";
import ActiveLink from "components/base/active-link/ActiveLink";
import Translate from "components/base/translate/Translate";
import { RouteNames, RouterQuery } from "utils/constants";
import { Svg } from "components/base/svg/Svg";
import styles from "./footer.module.sass";
import Default from "./layouts/default/Default";
import Flat from "./layouts/flat/Flat";

type IFooterContext = {
    enabledPoliciesConfig: IPolicyPagesConfig[];
    enabledBonusesConfig: IBonusPagesConfig[];
    renderBonusesLink: (item: IBonusPagesConfig) => JSX.Element | null;
    renderPoliciesLink: (item: IPolicyPagesConfig) => JSX.Element | null;
    renderSportLinks: JSX.Element;
    renderCasinoLinks: JSX.Element;
    renderSocialLinks: JSX.Element;
};

export const FooterContext = createContext<IFooterContext>(null as any);

const Footer: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const enabledPoliciesConfig = usePoliciesPagesConfig();
    const enabledBonusesConfig = useBonusPagesConfig();

    const renderPoliciesLink = useCallback((item: IPolicyPagesConfig) => {
        let content;
        switch (item.name) {
            case PolicyPagesEnum.Privacy:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.PRIVACY}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_privacy" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Terms:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.TERMS}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_terms" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Sports:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.SPORTSBOOK}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_rules" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Casino:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.CASINO}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_casino" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Payments:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.PAYMENTS}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_payments" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.ResponsibleGaming:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.RESPONSIBLE_GAMING}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_responsible_gaming" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Cookies:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.COOKIES}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_cookies" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.SelfExclusion:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.SELF_EXCLUSION}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_self_exclusion" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.DisputeResolution:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.DISPUTE_RESOLUTION}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_dispute_resolution" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.AntiMoneyLaundering:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.ANTI_MONEY_LAUNDERING}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_anti_money_laundering" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.FairnessRngTesting:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.FAIRNESS_RNG_TESTING}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_fairness_rng_testing" />
                    </ActiveLink>
                );
                break;
            case PolicyPagesEnum.Kyc:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.POLICIES}/${RouteNames.KYC}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_policies_kyc" />
                    </ActiveLink>
                );
                break;
            default:
                content = null;
        }
        return content;
    }, []);

    const renderBonusesLink = useCallback((item: IBonusPagesConfig) => {
        let content;
        switch (item.name) {
            case BonusPagesEnum.Referral:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.BONUS_REFERRAL}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_referral" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Cashback:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.CASHBACK}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_cashback" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promotions:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promotions" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Casino:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_casino" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Casino2:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Casino2}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_casino_2" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo2:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo2}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_2" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo3:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo3}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_3" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo4:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo4}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_4" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo5:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo5}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_5" />
                    </ActiveLink>
                );
                break;
            case BonusPagesEnum.Promo6:
                content = (
                    <ActiveLink
                        key={item.name}
                        href={`/${RouteNames.BONUSES}/${RouteNames.PROMOTIONS}/${RouterQuery.Promo6}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_bonuses_promo_6" />
                    </ActiveLink>
                );
                break;
            default:
                content = null;
        }

        return item.enable ? content : null;
    }, []);

    const renderSportLinks = useMemo(
        () => (
            <>
                <ActiveLink
                    href={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                    includes={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                    activeClassName={styles.active}>
                    <Translate tid="pageName_live" />
                </ActiveLink>
                <ActiveLink
                    href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${RouteNames.ALL}`}
                    includes={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                    activeClassName={styles.active}>
                    <Translate tid="pageName_sport" />
                </ActiveLink>
                <ActiveLink
                    href={`/${RouteNames.SPORT}/${RouteNames.CALENDAR}`}
                    activeClassName={styles.active}>
                    <Translate tid="pageName_calendar" />
                </ActiveLink>
                {COMMON_SITE_CONFIGS.sportResults && (
                    <ActiveLink
                        href={`/${RouteNames.SPORT}/${RouteNames.RESULTS}`}
                        activeClassName={styles.active}>
                        <Translate tid="pageName_results" />
                    </ActiveLink>
                )}
            </>
        ),
        [COMMON_SITE_CONFIGS.sportResults],
    );

    const renderCasinoLinks = useMemo(
        () => (
            <>
                <ActiveLink
                    href={`/${RouteNames.CASINO}/${RouteNames.LIVE_CASINO}`}
                    includes={`/${RouteNames.LIVE_CASINO}`}
                    activeClassName={styles.active}>
                    <Translate tid="pageName_livecasino" />
                </ActiveLink>
                <ActiveLink
                    href={`/${RouteNames.CASINO}/${RouteNames.SLOTS}`}
                    includes={`/${RouteNames.SLOTS}`}
                    activeClassName={styles.active}>
                    <Translate tid="pageName_slots" />
                </ActiveLink>
            </>
        ),
        [],
    );

    const renderSocialLinks = useMemo(
        () => (
            <>
                {COMMON_SITE_CONFIGS.socialPlatforms.facebook && (
                    <a
                        href={`https://facebook.com/${COMMON_SITE_CONFIGS.socialPlatforms.facebook}`}
                        className={styles.socialLink}>
                        <Svg
                            src="/common/footer/facebook.svg"
                            wrapper="span"
                            className="svg-icon"
                        />
                    </a>
                )}

                {COMMON_SITE_CONFIGS.socialPlatforms.instagram && (
                    <a
                        href={`https://instagram.com/${COMMON_SITE_CONFIGS.socialPlatforms.instagram}`}
                        className={styles.socialLink}>
                        <Svg
                            src="/common/footer/instagram.svg"
                            wrapper="span"
                            className="svg-icon"
                        />
                    </a>
                )}
                {COMMON_SITE_CONFIGS.socialPlatforms.twitter && (
                    <a
                        href={`https://twitter.com/${COMMON_SITE_CONFIGS.socialPlatforms.twitter}`}
                        className={styles.socialLink}>
                        <Svg src="/common/footer/twitter.svg" wrapper="span" className="svg-icon" />
                    </a>
                )}

                {COMMON_SITE_CONFIGS.socialPlatforms.youtube && (
                    <a
                        href={`https://youtube.com/@${COMMON_SITE_CONFIGS.socialPlatforms.youtube}`}
                        className={styles.socialLink}>
                        <Svg src="/common/footer/youtube.svg" wrapper="span" className="svg-icon" />
                    </a>
                )}
                {COMMON_SITE_CONFIGS.socialPlatforms.telegram && (
                    <a
                        href={`https://t.me/${COMMON_SITE_CONFIGS.socialPlatforms.telegram}`}
                        className={styles.socialLink}>
                        <Svg
                            src="/common/footer/telegram.svg"
                            wrapper="span"
                            className="svg-icon"
                        />
                    </a>
                )}
                {COMMON_SITE_CONFIGS.socialPlatforms.tiktok && (
                    <a
                        target="_blank"
                        href={`https://tiktok.com/@${COMMON_SITE_CONFIGS.socialPlatforms.tiktok}`}
                        className={styles.socialLink}>
                        <Svg src="/common/footer/tiktok.svg" wrapper="span" className="svg-icon" />
                    </a>
                )}
            </>
        ),
        [
            COMMON_SITE_CONFIGS.socialPlatforms.facebook,
            COMMON_SITE_CONFIGS.socialPlatforms.instagram,
            COMMON_SITE_CONFIGS.socialPlatforms.telegram,
            COMMON_SITE_CONFIGS.socialPlatforms.tiktok,
            COMMON_SITE_CONFIGS.socialPlatforms.twitter,
            COMMON_SITE_CONFIGS.socialPlatforms.youtube,
        ],
    );

    const renderLayout = useMemo(() => {
        switch (COMMON_SITE_CONFIGS.skinVersion) {
            case SkinVersions["10xbet"]:
                return <Flat />;
            default:
                return <Default />;
        }
    }, [COMMON_SITE_CONFIGS.skinVersion]);

    const value = useMemo(
        () => ({
            enabledPoliciesConfig,
            enabledBonusesConfig,
            renderBonusesLink,
            renderPoliciesLink,
            renderSportLinks,
            renderCasinoLinks,
            renderSocialLinks,
        }),
        [
            enabledBonusesConfig,
            enabledPoliciesConfig,
            renderBonusesLink,
            renderPoliciesLink,
            renderSportLinks,
            renderCasinoLinks,
            renderSocialLinks,
        ],
    );

    return <FooterContext.Provider value={value}>{renderLayout}</FooterContext.Provider>;
};

export default Footer;
