import { FC, useContext, useMemo } from "react";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import { formatAppNameText, WalletLayoutEnum } from "@finbackoffice/fe-core";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import { FooterContext } from "components/footer/Footer";
import Img from "components/base/img/Img";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import LicenseLogo from "components/footer/license-logo/LicenseLogo";
import styles from "./default.module.sass";

const Default: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const VERSION = useRuntimeConfig("VERSION");
    const {
        enabledBonusesConfig,
        enabledPoliciesConfig,
        renderBonusesLink,
        renderPoliciesLink,
        renderSportLinks,
        renderCasinoLinks,
        renderSocialLinks,
    } = useContext(FooterContext);

    const renderFooterCurrenciesSection = useMemo(() => {
        if (COMMON_SITE_CONFIGS.wallet.type === WalletLayoutEnum.Pix) {
            return <Svg src="/common/footer/pix.svg" wrapper="span" className="svg-icon" />;
        }

        return (
            <>
                <Svg src="/common/footer/bitcoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/ripple.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/dogecoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/litecoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/tron.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/usdt.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/ethereum.svg" wrapper="span" className="svg-icon" />
            </>
        );
    }, [COMMON_SITE_CONFIGS.wallet.type]);

    return (
        <footer className={styles.footer}>
            <nav className={styles.nav}>
                <div>
                    <h2>
                        <Translate tid="footer_sports" />
                    </h2>
                    {renderSportLinks}
                </div>
                <div>
                    <h2>
                        <Translate tid="footer_casino" />
                    </h2>
                    {renderCasinoLinks}
                </div>
                <div>
                    <h2>
                        <Translate tid="footer_bonuses" />
                    </h2>
                    {enabledBonusesConfig.map(renderBonusesLink)}
                </div>
                <div>
                    <h2>
                        <Translate tid="footer_help_center" />
                    </h2>
                    {enabledPoliciesConfig.map(renderPoliciesLink)}
                </div>
            </nav>
            {COMMON_SITE_CONFIGS.wallet.type !== WalletLayoutEnum.Tola && (
                <div className={styles.footerCrypto}>{renderFooterCurrenciesSection}</div>
            )}

            <nav className={styles.socialLinks}>
                <span className={styles.age}>18+</span>
                {renderSocialLinks}
            </nav>
            <div className={styles.footerText}>
                {COMMON_SITE_CONFIGS.showLicenseLogo && (
                    <ErrorBoundary name={LicenseLogo.name}>
                        <LicenseLogo />
                    </ErrorBoundary>
                )}
                <Translate
                    tid="footer_copyright_text"
                    dangerous
                    replace={{ app_name: COMMON_SITE_CONFIGS.appName }}>
                    <span className={styles.txt} />
                </Translate>
            </div>
            <div className={styles.footerLogo}>
                <Img
                    source={`${ASSETS_URL}/${formatAppNameText(
                        COMMON_SITE_CONFIGS.appName,
                    )}/mobile/logo.svg`}
                    width={0}
                    height={0}
                    style={{ width: "100%", height: "auto" }}
                    alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                    title={`${COMMON_SITE_CONFIGS.appName} logo`}
                />
                <h1>
                    <Translate
                        tid="footer_headText"
                        replace={{ app_name: COMMON_SITE_CONFIGS.appName }}
                    />
                </h1>
            </div>
            <div className={styles.footerSite}>{`© ${new Date().getFullYear()} ${
                COMMON_SITE_CONFIGS.appName
            }`}</div>
            <div className={styles.version}>
                <Translate tid="footer_version" replace={{ version: VERSION }} />
            </div>
        </footer>
    );
};

export default Default;
